import React, {Component} from "react"
import styles from "./style.module.css"

class TabSelection extends Component{
    
    constructor(props){
        super(props);

        this.state = {
            currentSelectedIndex: -1 
        }
    }

    setTabState=(state)=>{
        this.props.mainComponent.setState({selectedCategory:state});
    }

    onClick=(state)=>{
        // console.log(e.target.attributes.indexdata.value);
        this.setTabState(state);
        this.setState({currentSelectedIndex: state.index})
    }

    render(){

        return(
            <ul className={styles.root}>
                {
                    this.props.items.map((item, index)=>{
                        var style = this.state.currentSelectedIndex == item.index ? `${styles.list} ${styles.selected}` : `${styles.list}`
                        return <li className={style} key={`tabSelection_${index}`} indexdata={item.index} onClick={()=>this.onClick(item)}>{item.name}</li>
                    })
                }
            </ul>
        )
    }
}

export default TabSelection;