
export const noType = {
    index: -1,
    name: "ALL"
}

export const projectType = { 
    COMP: {
        index: 0,
        name: "Compositing"
    },
    DYNAMICS: {
        index: 1,
        name: "Dynamics"
    },
    MATCH_MOVING: {
        index: 2,
        name: "Match Moving"
    },
    TEX_LIGHT: {
        index: 3,
        name: "Texturing & Lighting"
    },
    MODELING: {
        index: 4,
        name: "Modeling"
    },
    ANIMATION: {
        index: 5,
        name: "Animation"
    },
    EDITING: {
        index: 6,
        name: "Editing"
    }

}



export const videoData = [
    {
        name: "Compass",
        image: require("../images/thumbnails/Thumb_MM_S11_compass_MM.png"),
        vimeoId: "334556252",
        year:"2019",
        type: [projectType.MATCH_MOVING]
    },
    {
        name: "Zoom",
        image: require("../images/thumbnails/Thumb_MM_S10_Zoom_MM.png"),
        vimeoId: "334556270",
        year:"2019",
        type: [projectType.MATCH_MOVING]
    },
    {
        name: "WaitingHi",
        image: require("../images/thumbnails/Thumb_MMS09_WaitingHi_MM.png"),
        vimeoId: "334556326",
        year:"2019",
        type: [projectType.MATCH_MOVING]
    },
    {
        name: "Blur",
        image: require("../images/thumbnails/Thumb_MM_S09_Blur_MM.png"),
        vimeoId: "334556355",
        year:"2019",
        type: [projectType.MATCH_MOVING]
    },
    {
        name: "Drone",
        image: require("../images/thumbnails/Thumb_MM_S07_Drone_MM.png"),
        vimeoId: "334556372",
        year:"2019",
        type: [projectType.MATCH_MOVING]
    },
    {
        name: "Sausalito",
        image: require("../images/thumbnails/Thumb_MM_Mid_Sausalito_MM.png"),
        vimeoId: "334556403",
        year:"2019",
        type: [projectType.MATCH_MOVING]
    },
    //----------------------------------------------------------------
    {
        name: "Server Room Under the Water",
        image: require("../images/thumbnails/Thumb_Server_Room_Under_the_Water_comp.png"),
        vimeoId: "334667047",
        year:"2019",
        type: [projectType.COMP]
    },
    {
        name: "Conversation",
        image: require("../images/thumbnails/Thumb_NC1_Conversation_comp.png"),
        vimeoId: "334551281",
        year:"2018",
        type: [projectType.COMP]
    },
    {
        name: "Room",
        image: require("../images/thumbnails/Thumb_NC1_Room_comp.png"),
        vimeoId: "334551342",
        year:"2018",
        type: [projectType.COMP]
    },
    {
        name: "Heart Cloud",
        image: require("../images/thumbnails/Thumb_NC1_HeartCloud_comp.png"),
        vimeoId: "334551317",
        year:"2018",
        type: [projectType.COMP]
    },
    {
        name: "Tatoo",
        image: require("../images/thumbnails/Thumb_NC1_Tatoo_Breakdown_comp.png"),
        vimeoId: "334551248",
        year:"2018",
        type: [projectType.COMP]
    },
    {
        name: "Ninja",
        image: require("../images/thumbnails/Thumb_Ninjya_comp.png"),
        vimeoId: "334551329",
        year:"2018",
        type: [projectType.COMP]
    },
    {
        name: "Desert",
        image: require("../images/thumbnails/Thumb_NC1_Desert_comp.png"),
        vimeoId: "334551294",
        year:"2018",
        type: [projectType.COMP]
    },
    {
        name: "Poster",
        image: require("../images/thumbnails/Thumb_NC1_Poster_comp.png"),
        vimeoId: "334551233",
        year:"2018",
        type: [projectType.COMP]
    },
    {
        name: "Night City",
        image: require("../images/thumbnails/Thumb_NC1_NightCity_comp.png"),
        vimeoId: "334551206",
        year:"2018",
        type: [projectType.COMP]
    },
    {
        name: "TV Screen",
        image: require("../images/thumbnails/Thumb_NC1_TVScreen_comp.png"),
        vimeoId: "334551194",
        year:"2018",
        type: [projectType.COMP]
    },
    //----------------------------------------------------------------
    {
        name: "DissolvingApple",
        image: require("../images/thumbnails/Thumb_DissolvingApple_FX.png"),
        vimeoId: "334677236",
        year:"2018",
        type: [projectType.DYNAMICS]
    },
    {
        name: "SkullFX",
        image: require("../images/thumbnails/Thumb_H2_SkullFX_FX.png"),
        vimeoId: "334677262",
        year:"2018",
        type: [projectType.DYNAMICS]
    },{
        name: "CityDriving Three Different Types",
        image: require("../images/thumbnails/Thumb_FTL_CityDriving_ThreeDiferemtType_Tex.png"),
        vimeoId: "334675991",
        year:"2017",
        type: [projectType.COMP, projectType.TEX_LIGHT]
    }
]

export const sort = (list, filterState) => {

    var res = [];

    if(filterState.index == noType.index) return list;
    
    for(var i in list){
        var item = list[i];
        for(var list_index in item.type){
            var type = item.type[list_index];
            if(type.index == filterState.index){
                res.push(list[i])
                break;
            };
        }
        
    }

    return res;
}
