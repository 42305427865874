import React, {Component} from "react";
import Layout from "../components/layout";
import styles from "./styles/work.module.css";
import { videoData, projectType, noType, sort } from "../data/demoReelData";
import Title from "../components/title"
import Grid from "../components/gridView"
import Modal from "../components/modal";
import TabSelectionHeader from "../components/tabSelectionHeader";


class Work extends Component{
    constructor(props){

        super(props);
        this.state = {
            modalOpen: false,
            selectedCategory: noType,
            currentModalData: null
        }
    }

    onOpenModal = (item) =>{
        this.setState({
            modalOpen: true,
            currentModalData: item
        });
    }

    onCloseModal = () =>{
        this.setState({
            modalOpen: false,
            currentModalData: null
        });
    }

    render(){
       
        var types = Object.keys(projectType).map(i => projectType[i])
        var items = [noType,...types];
        var sortedItems = sort(videoData,this.state.selectedCategory);
        // console.log(this.state.selectedCategory);
        //console.log(items)
        return(
            <Layout>
                <div className={styles.wrapper}>
                    <TabSelectionHeader mainComponent={this} items={items}/>
                    <div className={styles.root}>
                        {this.state.modalOpen ? <Modal onCloseModal={this.onCloseModal} data={this.state.currentModalData} /> : ""}
                    </div>

                    <Grid withTitle onOpenModal={this.onOpenModal} data={sortedItems}/>
                </div>
            </Layout>
        );
    }

}

export default Work;

/*

    render(){

        var iframeScale = this.getIframeScale();

        var modal = (
            <div className={styles.modalWindow} onClick={this.onClick}>
                <iframe ref={this.iframeRef} src="https://player.vimeo.com/video/192156622?color=ffffff&title=0&byline=0&portrait=0" frameBorder="0" width={iframeScale.w} height={iframeScale.h}></iframe>
            </div>
        )

        var items = [noType,projectType.FX,projectType.COMP];

        
        var sortedItems = sort(videoData,this.state.selectedCategory);
        console.log(this.state.selectedCategory);

        return(
            <Layout>
            
                <Title title="Work">
                    <TabSelection mainComponent={this} items={items}/>
                </Title>

                <div className={styles.root}>
                  
                    {this.state.modalOpen ? modal : ""}
                </div>

                <div className={styles.grid}>
                    {
                        sortedItems.map((item, index)=>{
                            return (
                                <div className={styles.video} key={`video_${index}`}>
                                    <div    key={`video_${index}`}
                                            style={{ "backgroundImage":`url(${item.image})` }}
                                            onClick={this.onClick}>

                                            <div className={styles.info}>
                                                <div>
                                                    <h1>{item.name}</h1>
                                                  
                                                </div>
                                            </div>

                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

            </Layout>
        );
    }
*/