import React, { Component } from 'react'
import TabSelection from "../tabSelection"
import styles from "./style.module.css"

export default class TabSelectionHeader extends Component {
  render() {
    return (
        <div className={styles.main}>
            <h4>{this.props.mainComponent.state.selectedCategory.name}</h4>
            <TabSelection mainComponent={this.props.mainComponent} items={this.props.items}/>
        </div>
    )
  }
}
